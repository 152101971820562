import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import TeamMemberCard from "components/team-member-card"

const StyledTeam = styled.section`
  padding: 60px 0 32px 0;

  ${breakpoint.medium`
    padding: 96px 0 99px 0;

    .col-12 {
      padding-right: 16px;
      padding-left: 16px;
    }
  `}

  .team__title {
    margin-bottom: 48px;

    ${breakpoint.medium`
      margin-bottom: 60px;
    `}
  }
`

const Team = () => {
  const {
    teamMembers: { teamMembers },
  } = useStaticQuery(graphql`
    query {
      teamMembers: contentfulAboutUs(title: { eq: "About Us" }) {
        teamMembers {
          ...TeamMember
        }
      }
    }
  `)

  return (
    <StyledTeam className="bg--silver__pale">
      <Container>
        <h2 className="team__title h2 maxi text-center">Leadership Team</h2>

        <div className="row">
          {teamMembers.map((teamMember) => (
            <div className="col-12 col-sm-4 mb-4" key={teamMember.fullName}>
              <TeamMemberCard {...teamMember} />
            </div>
          ))}
        </div>
      </Container>
    </StyledTeam>
  )
}

export default Team
