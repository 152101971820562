import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"

// Icons
import Hope from "assets/icons/about-us/icon-hope.inline.svg"
import Equity from "assets/icons/about-us/icon-equity.inline.svg"
import Growth from "assets/icons/about-us/icon-growth.inline.svg"
import Oportunity from "assets/icons/about-us/icon-opportunity.inline.svg"

const StyledOurVision = styled.section`
  background: ${colors.navy__extra_dark};
  color: white;
  padding: 32px 0 0 0;

  ${breakpoints.medium`
    padding: 140px 0 66px 0;
  `}

  .item {
    margin-bottom: 50px;

    ${breakpoints.medium`
      margin-bottom: 130px;
    `}

    svg {
      margin-bottom: 24px;

      ${breakpoints.medium`
        margin-bottom: 32px;
      `}
    }
  }

  .title {
    margin-bottom: 32px;

    ${breakpoints.medium`
      margin-bottom: 120px;
    `}
  }

  .our-vision {
    p {
      ${breakpoints.medium`
        max-width: 355px;
        width: 100%;
      `}
    }
  }
`

const OurVision = () => (
  <StyledOurVision>
    <Container>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <h2 className="h2 maxi text-center title">Our Vision</h2>
          <div className="our-vision row justify-content-between">
            <div className="col-12 col-sm-6 col-md-5">
              <div className="item">
                <div>
                  <Hope />
                </div>

                <h3 className="h3 mt-2 mb-2">Hope</h3>
                <p className="text--normal color--silver__light">
                  Hope is our belief that the future can be better than the
                  present or the past, and that our actions and behaviors will
                  help us create this.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-5">
              <div className="item">
                <div>
                  <Oportunity />
                </div>

                <h3 className="h3 mt-2 mb-2">Opportunity</h3>
                <p className="text--normal color--silver__light">
                  Every individual should have the opportunity to find work that
                  is meaningful and creates a pathway to a better financial
                  future.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-5">
              <div className="item">
                <div>
                  <Growth />
                </div>

                <h3 className="h3 mt-2 mb-2">Growth</h3>
                <p className="text--normal color--silver__light">
                  Meaningful work enables growth and builds resources that allow
                  greater fulfillment for individuals and society as a whole.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-5">
              <div className="item">
                <div>
                  <Equity />
                </div>

                <h3 className="h3 mt-2 mb-2">Equity</h3>
                <p className="text--normal color--silver__light">
                  By connecting people with meaningful work without biases and
                  incorrect assumptions, we will contribute to a more just
                  society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </StyledOurVision>
)

export default OurVision
