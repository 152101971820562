import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Images
import ImageMobile from "assets/images/about-us/hero-image--mobile.png"
import ImageDesktop from "assets/images/about-us/hero-image.png"

const StyledHero = styled.section`
  // max-height: -webkit-fill-available;
  min-height: 70vh;
  padding: 60px 0 120px 0;
  background-image: url(${ImageMobile});
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  text-align: center;

  ${breakpoint.small`
    text-align: left;
  `}

  ${breakpoint.medium`
    min-height: calc(100vh - 96px);
    padding: 104px 0;
    background-image: url(${ImageDesktop});
    background-size: 
  `}
`

const Hero = () => (
  <StyledHero>
    <Container>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-6">
          <h1 className="h1 maxi">
            Talent is Evenly Distributed.
            <br />
            Opportunity is Not.
          </h1>
        </div>
      </div>
    </Container>
  </StyledHero>
)

export default Hero
