import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconQuote from "assets/icons/about-us/icon-quote.inline.svg"
import { colors } from "utils/variables"

const StyledQuote = styled.section`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  padding: 120px 0;
  overflow: hidden;

  ${breakpoint.small`
    max-height: 488px;
    min-height: auto;
    heigth: auto;
  `}

  .quote__image {
    padding: 2px;
    border: 2px solid ${colors.turquoise__primary};
    border-radius: 50%;
  }

  .quote__icon {
    max-width: 408px;
    width: 60%;
    height: auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

const Quote = () => (
  <StyledQuote id="quote" className="bg--navy__extra_dark color--white">
    <Container>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-9">
          <div className="quote">
            <div className="quote__content">
              <p className="h2 mb-3 font-weight--700">
                “Arena Analytics is a cozy company full of hard-working,
                kind-hearted people that care about our customers, our work, and
                our unity as a team. Arena’s greatest strength is the collective
                devotion teammates show to their work and to each other. I’ve
                made lifelong friends here.”
              </p>

              <div className="d-flex align-items-center">
                <div className="quote__image me-3">
                  <StaticImage
                    src="../../assets/images/about-us/quote-image.jpg"
                    alt=""
                    imgStyle={{ borderRadius: "50%", overflow: "hidden" }}
                    width={58}
                  />
                </div>

                <div>
                  <p className="text--large mb-1 color--turquoise__primary font-weight--700">
                    Katharine Yi
                  </p>

                  <p>
                    <span className="d-block mb-1 color--turquoise__primary">
                      Director of Information Security
                    </span>
                    <span className="d-block color--silver__light">
                      Arena Analytics
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <IconQuote className="quote__icon" />
  </StyledQuote>
)

export default Quote
