import React from "react"

// Libraries
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections

// Sections
import Hero from "sections/about-us/hero"
import OurGoal from "sections/about-us/our-goal"
import Quote from "sections/about-us/quote"
import OriginStory from "sections/about-us/origin-story"
import OurVision from "sections/about-us/our-vision"
import Team from "sections/about-us/team"
import Careers from "sections/about-us/careers"

const AboutUs = () => (
  <Layout showNewsletter={false}>
    <SEO
      title="About Us | Predictive Analytics in Hiring | Arena Analytics"
      description="At Arena Analytics, we believe that talent is equally distributed, but opportunity is not. Our goal is to use predictive analytics to require the labor market."
    />
    <Hero />
    <OurGoal />
    <Quote />
    <OriginStory />
    <OurVision />
    <Team />
    <Careers />
  </Layout>
)

export default AboutUs
