import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Components
import Container from "components/container/"

// Utils
import breakpoints from "utils/breakpoints/"
import { colors } from "utils/variables"

const StyledOriginStory = styled.section`
  background: ${colors.silver__pale};
  padding: 60px 0;

  ${breakpoints.medium`
    padding: 120px 0;
  `}

  .title {
    margin-bottom: 60px;

    ${breakpoints.medium`
      margin-bottom: 115px;
    
    `}
  }

  .item {
    margin-bottom: 90px;

    ${breakpoints.medium`
      margin-bottom: 170px;
    
    `}
  }
`

const OriginStory = () => (
  <StyledOriginStory>
    <Container>
      <h2 className="title h2 maxi text-center">Origin Story</h2>

      <div className="item row justify-content-around align-items-center">
        <div className="col-12 col-sm-5 order-sm-1">
          <StaticImage
            src="../../assets/images/about-us/michael-rosenbaum.png"
            alt=""
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 order-sm-0">
          <h1 className="h1 mb-3">In the beginning</h1>
          <p className="text--large">
            Arena Analytics was formally founded by Michael Rosenbaum in 2015.
            As a White House Fellow from Harvard University, Rosenbaum
            discovered that candidates’ performance could be predicted by data,
            which he theorized could help improve the labor market and reduce
            inequality.
          </p>
        </div>
      </div>

      <div className="item row justify-content-around align-items-center">
        <div className="col-12 col-sm-5 mb-3 mb-sm-0">
          <StaticImage src="../../assets/images/about-us/catalyte.png" alt="" />
        </div>

        <div className="col-12 col-sm-6 col-md-4">
          <h1 className="h1 mb-3">Arena’s catalyst</h1>
          <p className="text--large">
            In 2001 Rosenbaum founded{" "}
            <a
              href="https://www.catalyte.io/"
              className="color--turquoise__primary color-hover--navy__dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              Catalyte
            </a>
            , an analytics engine for selecting candidates based on data, which
            today continues to realize substantial growth and garner praise from
            clients and press. The core technology behind Catalyte was the
            original impetus for the creation of Arena Analytics.
          </p>
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-12 col-sm-6 mb-4 order-sm-1">
          <StaticImage
            src="../../assets/images/about-us/shady-grove-adventist.png"
            alt=""
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 mb-5 order-sm-0">
          <h1 className="h1 mb-3">Our first client</h1>
          <p className="text--large">
            Our first client was Shady Grove Adventist Hospital and our goal was
            to reduce turnover by 10%. In fact, by the time machine learning
            effects were applied, turnover was decreased by 77%. In 2016 we
            changed the name to Arena Analytics, and the rest is history (in the
            making).
          </p>
        </div>
      </div>
    </Container>
  </StyledOriginStory>
)

export default OriginStory
