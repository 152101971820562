import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container/"
import Button from "components/button/"
import breakpoints from "utils/breakpoints"

// Icon
import Background from "assets/icons/about-us/icon-careers.inline.svg"
import { careersLink } from "utils/variables"

const StyledCareers = styled.section`
  padding: 120px 0;
  text-align: center;

  ${breakpoints.medium`
    padding: 158px 0 180px 0;
    position: relative;
  `}

  .icon {
    display: none;

    ${breakpoints.medium`
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    `}
  }
`
const Careers = () => (
  <StyledCareers>
    <Container>
      <div>
        <h2 className="h2 maxi">Career Opportunities</h2>
        <p className="text--large mt-3 mb-5">
          Want to join us on our mission to rewire the labor market?
        </p>
        <Button type="link" to={careersLink}>
          Browse open positions
        </Button>
        <Background className="icon" />
      </div>
    </Container>
  </StyledCareers>
)

export default Careers
