import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container/"

// Utils
import breakpoint from "utils/breakpoints/"

// Icons
import IconOurGoal from "assets/icons/about-us/icon-our-goal.inline.svg"

const StyledOurGoal = styled.section`
  padding: 60px 0;
  overflow: hidden;

  ${breakpoint.medium`
    padding: 115px 0 170px;
  `}

  .our-goal__wrapper {
    position: relative;
    padding: 48px 24px;
    border-radius: 20px;

    ${breakpoint.medium`
      padding: 48px;
    `}

    ${breakpoint.large`
      padding: 76px 58px;
    `}

    svg {
      position: absolute;
      left: -12px;
      bottom: -30px;
    }
  }
`

const OurGoal = () => (
  <StyledOurGoal>
    <Container>
      <div className="row align-items-center justify-content-between">
        <div className="col-12 col-sm-8 col-md-5 mb-5 mb-md-0">
          <div className="our-goal__wrapper bg--silver__extra_pale">
            <p className="h1 font-weight--400">
              <b>Our goal is to rewire the labor market</b> by applying
              predictive analytics, data science, and artificial intelligence to
              transform the way organizations identify, match, and hire people.
            </p>

            <IconOurGoal />
          </div>
        </div>

        <div className="col-12 col-sm-4 col-md-6">
          <h2 className="h1 mb-3">Focusing on Outcomes</h2>
          <p className="text--large">
            We believe that the fundamental imbalance between talent and
            opportunity in the workforce harms everyone, preventing employers
            from identifying and hiring people who can significantly contribute
            to the success of their organizations, and limiting the potential,
            productivity, and satisfaction of employees who too often miss out
            on great opportunities. By revealing each individual’s likely
            performance, the Arena solution is transforming the labor market
            from one based on perception and unconscious bias, to one based on
            outcomes.
          </p>
        </div>
      </div>
    </Container>
  </StyledOurGoal>
)

export default OurGoal
